<template lang="pug">
.no-org
  div(class='max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8')
  .text-center.d-flex.align-center.flex-column
    svg.h-12.w-12.text-red-400(xmlns='http://www.w3.org/2000/svg', fill='none', viewBox='0 0 24 24', stroke='currentColor')
      path(stroke-linecap='round', stroke-linejoin='round', 
        stroke-width='2',
        d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z')
    p(class='text-4xl font-bold text-gray-900 mt-8 sm:text-5xl sm:tracking-tight lg:text-5xl') 
      | {{ context.entity }} Setup Required
    p.max-w-xl.mt-5.mx-auto.text-xl.text-gray-500 
      | For all features to be enabled, set up your {{ context.entity }}
    v-btn.mt-8( 
      @click="isCreateEditOpen = true" 
      color='primary', 
      depressed
    ) Set one up
  
  OrgCreateEditDialog(
    :isOpen="isCreateEditOpen"
    @close="isCreateEditOpen = false"
    v-if="!context.isCandidate && isCreateEditOpen"
  )
  
  CommitteeCreateEditDialog(
    :isOpen="isCreateEditOpen"
    @close="isCreateEditOpen = false"
    v-if="context.isCandidate && isCreateEditOpen"
  )
  
</template>

<script>
import OrgCreateEditDialog from '@/views/organizations/Org.CreateEdit.Dialog'
import CommitteeCreateEditDialog from '@/views/organizations/Committee.CreateEdit.Dialog'
import { ref } from '@vue/composition-api'

export default {
  name: 'NoOrganization',

  components: {
    OrgCreateEditDialog,
    CommitteeCreateEditDialog
  },

  setup (props, { root: { $store, $router, $snackSuccess } }) {
    const { group, organizationId } = $store.getters['auth/user'];
    const isCreateEditOpen = ref(false);

    let context = {}
    if (group === 'organization') {
      context = {
        isCandidate: false,
        entity: 'Organization',
        group
      }
    } else {
      context = {
        isCandidate: true,
        entity: 'Committee',
        group
      }
    }

    if (organizationId) {
      $snackSuccess(context.entity + ' Created, all features available!')
      $router.push('/dashboard')
    }

    return {
      isCreateEditOpen,
      context
    }
  }
}
</script>

<style>

</style>